.btn-col {
    padding-left: 15px;
    padding-right: 15px;
  }

.twoColumnFlex {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.twoColumnFlex > .col-md-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* HomePage.css */

.hero-section-home {
  min-height: 100vh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(5, 82, 5, 0.2)), url('../../images/home-image.jpg');
  background-size: cover;
  background-position: center;
}

.hero-content {
  color: white;
}

.card {
  margin: 10px 0 20px 0;
  background: transparent;
}

.portfolio-section {
  padding: 50px 0; /* Adjust as needed */
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url('../../images/kitchen.jpg');
  background-size: cover;
  background-position: center;
  color: #fff; /* Change the color as needed */
  margin-top: 20px; /* Adjust as needed */
  margin-bottom: 20px; /* Adjust as needed */
}