.logo:hover {
    transform: scale(1.1); /* Increase the size of the logo by 10% */
  }
.align-right {
    text-align: right;
  }

body {
  overflow-x: hidden;
}

nav button {
  margin: 1em;
}

#responsive-navbar-nav {
  padding: 1em;
}

.menu-button {
  border: 1px solid #000; /* Change the color as needed */
  border-radius: 15px; /* Adjust as needed */
  padding: 10px 20px; /* Adjust as needed */
  margin: 0 5px; /* Adjust as needed */
  transition: background-color 0.3s ease;
}

.menu-button:hover {
  background-color: #f8f9fa; /* Change the color as needed */
  color: #000; /* Change the color as needed */
}