/* CommonPageStyles.css */

.hero-section-common {
    min-height: 100vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(161, 163, 161, 0.6)), url('../images/garden-inside.jpg');
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .container-center {
    height: 100vh;
  }
  
  .contact-col {
    padding: 100px 10px;
    background-image: linear-gradient(rgba(3, 82, 20, 0.7), rgba(2, 184, 93, 0.9)), url('../images/kitchen.jpg');
    background-size: cover;
    background-position: left;
    color: #fff; 
    color: white;
    margin-top: 10px;
    font-size: 1rem;
  }

  
  
  
  
  @media (max-width: 768px) { 
    .custom-form {
      margin: 100px 0 100px 0;
    }
    .bottom-align {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%; 
    }
    .form-of-contact {
      margin: 50px 0 30px 0;
    }
    .hero-section-common {
      width: 100% !important;
    }
  }