.appointment-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #90ee90; /* Light green */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(11, 146, 11, 0.4)), url('../images/garden-inside.jpg');
    color: white;
  }
  
  .hero {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .appointment-section {
    text-align: center;
  }
  
  .appointment-section a {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    background-color: #4285f4; /* Google Meet blue */
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .appointment-section a:hover {
    background-color: #357ae8; /* Darker blue on hover */
  }

  p.text-center {
    text-align: center;
    padding: 1em;
  }