

.title-top {
    margin-top: 100px;
}

.content {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 15px;
  }
  
  .title {
    color: #148541;
    margin-bottom: 20px;
  }
  
  .body {
    color: #333;
    margin-bottom: 20px;
    line-height: 1.5;
  }