.sitemap-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .sitemap-section {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sitemap-section h1 {
    text-align: center;
    color: #333;
  }
  
  .sitemap-section ul {
    list-style: none;
    padding: 0;
  }
  
  .sitemap-section li {
    margin: 10px 0;
  }
  
  .sitemap-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  .sitemap-section a:hover {
    text-decoration: underline;
  }