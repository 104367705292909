.construction-services-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .hero {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .construction-services-section {
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 80px; /* Adjust this value based on the height of your menu */
  }
  
  .quote-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .quote-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .quote-button:hover {
    background-color: #0056b3;
  }
  
  .quote-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .quote-form label {
    margin-top: 10px;
  }
  
  .quote-form input,
  .quote-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .quote-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .quote-form button:hover {
    background-color: #0056b3;
  }
  
  .thank-you-message {
    text-align: center;
    margin-top: 20px;
  }
  
  .thank-you-message h2 {
    color: #007bff;
  }
  
  .thank-you-message p {
    font-size: 16px;
  }