  body {
      overflow-x: hidden;
    }

  .hero-section {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('../../images/livingroom-kitchen-view.jpg');
      background-size: cover;
      background-position: center;
      color: white;
    }

  .hero-section-2 {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url('../../images/kitchen.jpg');
      background-size: cover;
      background-position: center;
      color: white;
    }

    ul.custom-list {
      list-style-type: none;
    }

    ul.custom-list li {
      padding-left: 1em;
      text-indent: -1em;
      text-align: left;
      padding-right: 1em;
    }

    ul.custom-list li:before {
      content: "\2192";
      padding-right: 10px;
    }

    p.text-left {
      text-align: left;
      padding: 1em;
    }

    .service-boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px;
      background-color: #f8f9fa; /* Change the color as needed */
    }
    
    .service-boxes div {
      flex: 1 0 21%; /* Adjust as needed */
      margin: 10px;
      padding: 20px;
      border: 1px solid #000; /* Change the color as needed */
      border-radius: 10px; /* Adjust as needed */
      background-color: #fff; /* Change the color as needed */
    }

    .services-title {
      padding: 20px;
      background-color: #f8f9fa; /* Change the color as needed */
      text-align: center;
    }