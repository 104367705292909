/* AboutPage.css */

.hero-section-1 {
    min-height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(7, 179, 22, 0.3)), url('../../images/stairs.jpg');
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .hero-section-2 {
    min-height: 100vh;
    background-image: url('../../images/stairs.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .separator {
     padding: 5rem 0;
  }